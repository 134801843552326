<template>

  <validation-observer ref="simpleRules">
    <div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <!-- <this-header /> -->
        <div class="anan-set-nav-conttainer mt-150">
          <router-link :to="{ name: 'new-pages' }">
            <b-button class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none">
              <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
            </b-button>
          </router-link>
          <div class="row">
            <div class="col-md-2 col-lg-2 col-sm-12">
              <side-bar class="d-none d-md-block" />
            </div>
            <div class="col-md-10 col-lg-10 col-sm-12">
              <h3>{{ $t('myPointValue') }} </h3>

              <div class="xMDeox mt-1">

                <div class="NpdN3L">

                  <img
                    class="jlW61a"
                    src="@/assets/images/anan-img/icon/point.svg"
                  >

                  <div class="ZBdeXm">
                    {{ getAmountPrefix(point) }} {{ Commas(point) }}

                  </div>
                  <div>
                    <div class="W+Iuswt">
                      <div class="pOz21+">
                        {{ $t('availablePoints') }}
                      </div>
                    </div>
                  </div>

                  <a
                    class="R-b3DT"
                    href="javascript:void(0)"
                    @click="pointswop()"
                  >
                    <span>{{ $t('exchangePoints') }}<i class="far fa-chevron-right ml-50" /></span>
                  </a>
                  <small
                    class="text-danger ml-5 mt-4"
                    style="position:absolute;"
                  >
                    * {{ $t('allShippingChinaToThailand') }} 150 {{ $t('baht') }} = 1 Point
                  </small>
                </div>

                <div>
                  <div class="NFPrbs">
                    <div
                      :class="`Ds9jc9 ${active === 0 ? '_4ypvzw' : ''}`"
                      @click="getData(status = 0, active = 0)"
                    >
                      {{ $t('fullHistory') }}
                    </div>

                    <div
                      :class="`Ds9jc9 ${active === 1 ? '_4ypvzw' : ''}`"
                      @click="getData(status = 1, active = 1)"
                    >
                      {{ $t('receivedHistory') }}
                    </div>

                    <div
                      :class="`Ds9jc9 ${active === 2 ? '_4ypvzw' : ''}`"
                      @click="getData(status = 2, active = 2)"
                    >
                      {{ $t('exchangeHistory') }}
                    </div>
                  </div>
                </div>

                <div>
                  <div class="rXcU7s">
                    <div>
                      <div
                        v-for="(item, index) in items"
                        :key="index"
                        class="bsb3JW"
                      >
                        <img
                          src="@/assets/images/anan-img/icon/point.svg"
                          height="65"
                        >
                        <div class="Qjnl2T">
                          <!-- <p
                          v-if="item.type == 18"
                          class="mb-0"
                        >
                          {{ item.subtype_text }}
                        </p> -->
                          <p class="mb-0">
                            <span v-if="item.type == 18">{{ item.subtype_text }}</span>
                            <span v-if="item.type == 19">{{ item.subtype_text }}</span>
                            <span v-else>{{ item.type_text }}</span>
                          </p>
                          <span>{{ item.details ? item.details : '' }}</span>
                          <div>
                            <small class="text-muted">{{ time(item.updated_at ? item.updated_at : '') }}</small>
                          </div>
                        </div>
                        <div class="R519Sm _5Q-g4s">
                          <span :style="getAmountStyle(item.type)">
                            {{ getAmountPrefix(item.type) }} {{ Commas(item.amount) }} p
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="demo-spacing-0 d-flex justify-content-end p-1">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @input="getData(status, search_val)"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>

              </div>

            </div>

          </div>

        </div>
      </b-overlay>
    </div>
    <b-modal
      id="modal-point"
      :title="$t('redeemPoints')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok.prevent="submitAddNew()"
    >
      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="Amount"
          rules="required|positive"
        >
          <label for="amount">{{ $t('key-145') }} : <span class="text-danger">*</span></label>
          <b-form-input
            id="Amount"
            v-model="point_amount"
            type="number"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('key-181')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>

    </b-modal>
    <b-modal
      id="add-addr"
      :title="$t('addNewAddress')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok.prevent="addAddr()"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          :label="$t('key-77')"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="fullName"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          :label="$t('key-146')"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="phoneNumber"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          :label="$t('addressNumber')"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-province-name"
          :label="$t('province')"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <b-form-input
              id="h-province-name"
              v-model="province"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          :label="$t('district')"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <b-form-input
              id="h-district-name"
              v-model="district"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          :label="$t('subdistrict')"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <b-form-input
              id="h-sub-district-name"
              v-model="subDistrict"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          :label="$t('postalCode')"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalCode"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </validation-observer>

</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BModal,
  BFormTextarea,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment-timezone'
import SideBar from '../profile/component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // vSelect,
    BModal,
    SideBar,
    BPagination,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // Validation
      required,
      active: 0,
      code: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 8,
      pageOptions: [10, 25, 50, 100],
      otpDigits: ['', '', '', ''],
      // Add Address
      Address: [],
      fullName: null,
      phoneNumber: null,
      address: null,
      province: null,
      district: null,
      subDistrict: null,
      postalCode: null,
      items: [],
      point: null,
      point_amount: 0,
      status: 0,
      showOver: true,
    }
  },
  mounted() {
    this.getData(this.status)
    this.getpoints()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    async getData(type) {
      try {
        const params = {
          type,
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/points/getPointuser', { params })
        this.items = res.data
        this.totalRows = res.total
        // totalRows
        this.showOver = false
        // console.log(this.items)
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
      // this.active = id
    },
    async getpoints() {
      try {
        const { data: res } = await this.$http.get('/points/getBalancepoint')
        this.point = res.points
      } catch (e) {
        console.log(e)
      }
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          const Obj = {
            fullName: this.fullName,
            phoneNumber: this.phoneNumber,
            address: this.address,
            province: this.province,
            district: this.district,
            subDistrict: this.subDistrict,
            postalCode: this.postalCode,
          }
          this.Address.push(Obj)
          this.$bvModal.hide('add-addr')
        }
      })
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [16, 18].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    submitAddNew() {
      if (this.point < this.point_amount) {
        this.SwalError('จำนวนแต้มไม่เพียงพอ')
      } else if (!this.point_amount) {
        this.SwalError('ป้อนจำนวนแต้มอีกครั้ง')
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            this.$bvModal.msgBoxConfirm(`ยืนยันแลกแต้ม จำนวนแต้ม ${this.point_amount} p`, {
              title: 'ยืนยันข้อมูล',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }).then(value => {
              if (value) {
                const obj = {
                  point_amount: this.point_amount,
                }
                this.$http.post('/wpoints/StoreUpdate', obj)
                  .then(() => {
                    this.getData()
                    this.getpoints()
                    this.Success('กรุณารอเจ้าหน้าที่ตรวจสอบ')
                    this.point_amount = 0
                    this.$bvModal.hide('modal-point')
                  })
                  .catch(e => {
                    this.SwalError(e)
                  })
              }
            })
          }
        })
      }
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'warning' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      })
    },
    pointswop() {
      this.$bvModal.show('modal-point')
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [16, 18].includes(value)) {
          return '-'
        }
        if (value && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 19].includes(value)) {
          return '+'
        }
      }
      return ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAddAddr() {
      this.fullName = null
      this.phoneNumber = null
      this.address = null
      this.province = null
      this.district = null
      this.subDistrict = null
      this.postalCode = null
      this.$bvModal.show('add-addr')
    },
  },
}
</script>
<style>
.otp-digit {
  width: 80px;
  height: 80px;
  margin: 5px;
  font-size: 40px;
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
